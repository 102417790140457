import {LitElement, html, css} from 'lit';
import '@vaadin/tabs';
import '@vaadin/tabsheet';
import {Notification} from '@vaadin/notification';
import './cccc-schema-form.js';
export class FormPlayground extends LitElement {

    static properties = {
        schema: { type: Object },
        uiSchema: { type: Object },
        theme: {type: String},
        formData: {type: Object},
    }

    constructor() {
        super();

        this.schema = {
            "title": "Hello World Form - From Playground",
            "type": "object",
            "required": ["title"],
            "properties": {
                "title": {"type": "string", "title": "Title"},
                "done": {"type": "boolean", "title": "Done?", "default": false}
            }
        }
        this.uiSchema = {};
        this.formData = {
            "title": "Title from data",
            "done": true
        };
    }

    render(){
        return html`

            <vaadin-tabsheet>
                <vaadin-tabs slot="tabs">
                    <vaadin-tab id="example-tab">Simple Example</vaadin-tab>
                    <vaadin-tab id="schema-example-tab">Schema Example</vaadin-tab>
                    <vaadin-tab id="getting-started-tab">Getting Started</vaadin-tab>
                </vaadin-tabs>

                <div tab="example-tab">
                    <cccc-schema-form
                            @formChange=${this.setFormData}
                            theme="antd"
                            schema=${JSON.stringify(this.schema)}
                            uiSchema=${JSON.stringify(this.uiSchema)}
                            formData=${JSON.stringify(this.formData)}
                    >
                    </cccc-schema-form>
                </div>
                <div tab="schema-example-tab">
                    <fieldset>
                        <legend>Form</legend>
                        <cccc-schema-form
                                @formChange=${this.setFormData}
                                theme="antd"
                                schema=${JSON.stringify(this.schema)}
                                uiSchema=${JSON.stringify(this.uiSchema)}
                                formData=${JSON.stringify(this.formData)}
                        >
                        </cccc-schema-form>
                    </fieldset>
                    <vaadin-tabsheet>
                        <vaadin-tabs slot="tabs">
                            <vaadin-tab id="schema">Schema</vaadin-tab>
                            <vaadin-tab id="ui">UI Schema</vaadin-tab>
                            <vaadin-tab id="data">data</vaadin-tab>
                        </vaadin-tabs>
                        <fieldset tab="schema">
                            <legend>Schema</legend>
                            <pre id="schema-editor" contenteditable="true">${JSON.stringify(this.schema, null, 2)}</pre>
                            <button theme="primary" @click=${this.updateSchema}>Update Schema</button>
                            
                        </fieldset>
                        <fieldset tab="ui">
                            <legend>UI Schema</legend>
                            <pre id="schema-ui-editor" contenteditable="true">${JSON.stringify(this.uiSchema, null, 2)}</pre>
                            <button theme="primary" @click=${this.updsateUiSchema}>Update UI Schema</button>                            
                        </fieldset>
                        <fieldset tab="data">
                            <legend>Form Data</legend>
                            <pre id="form-data-editor" contenteditable="true">${JSON.stringify(this.formData, null, 2)}</pre>
                            <button @click=${this.updateFormData}>Update Data</button>
                        </fieldset>
                    </vaadin-tabsheet>
                </div>
                <div tab="getting-started-tab">
                    
                    <h1>Getting Started</h1>
                    
                    <div>
                        First include the cccc-schema-forms web-component script
                        
                    </div>
                    <div>
                        <textarea style="width:100%; height: 30px"><script type="module" src="https://ф.cc/schema-forms/cccc-schema-form.js"></script></textarea>
                    </div>
                    <div>
                    Then add the web-component to your page
                    </div>
                    <div>
                        <textarea style="width:100%; height: 350px">
<cccc-schema-form>
{ 
    schema: {
        "title": "Hello World Form - From Playground",
        "type": "object",
        "required": ["title"],
        "properties": {
            "title": {"type": "string", "title": "Title"},
            "done": {"type": "boolean", "title": "Done?", "default": false}
        }
    }
    uiSchema: {};
    formData: {
        "title": "Title from data",
        "done": true
    }
}
</cccc-schema-form>
                        </textarea>
                    </div>
                    <div>
                    You can include the schema, uiSchema and formData as attributes or as innerText
                    </div>
                    <div>
                    If you don't care about the uiSchema & data you can add the schema directly to the content
                    </div>
                                       
                </div>
            </vaadin-tabsheet>
            
        `;
    }

    updateSchema(){
        const newSchema = this.shadowRoot.querySelector('#schema-editor').innerText
        try {
            this.schema = JSON.parse(newSchema);
            this.requestUpdate('schema')
        } catch(e) {
            const notification = Notification.show(`Failed to parse json. ${e}`, {
                position: 'bottom-center',
                duration: 5000,
                theme:"warning"
            });
            notification.addEventListener('click', () => {
                console.log('Notification clicked')
                notification.close()
            })

        }
    }
    updateFormData(){
        const newSchema = this.shadowRoot.querySelector('#form-data-editor').innerText
        try {
            this.formData = JSON.parse(newSchema);
            this.requestUpdate('formData')
        } catch(e) {
            const notification = Notification.show(`Failed to parse json. ${e}`, {
                position: 'bottom-center',
                duration: 5000,
                theme:"warning"
            });
            notification.addEventListener('click', () => {
                console.log('Notification clicked')
                notification.close()
            })

        }
    }
    updateUISchema(){
        const newSchema = this.shadowRoot.querySelector('#schema-ui-editor').innerText
        try {
            this.uiSchema = JSON.parse(newSchema);
            this.requestUpdate('uiSchema')
        } catch(e) {
            const notification = Notification.show(`Failed to parse json. ${e}`, {
                position: 'bottom-center',
                duration: 5000,
                theme:"warning"
            });
            notification.addEventListener('click', () => {
                console.log('Notification clicked')
                notification.close()
            })

        }
    }
    setFormData(e){
        const data = e.detail.formData
        this.formData = data
        this.requestUpdate('formData')
        console.log('form data changed', data)
    }
}

customElements.define('cccc-schema-playground', FormPlayground);

setTimeout(() => {
    // real form is encased in shadow dom, if not document world work...
    const document = window.document.querySelector('cccc-schema-playground').shadowRoot

    document.querySelector('cccc-schema-form').addEventListener('change', (event) => {
        console.log('EVENT FIRED [change]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('submit', (event) => {
        console.log('EVENT FIRED [submit]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('error', (event) => {
        console.log('EVENT FIRED [error]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('focus', (event) => {
        console.log('EVENT FIRED [focus]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('blur', (event) => {
        console.log('EVENT FIRED [blur]', event);
    });


    document.querySelector('cccc-schema-form').addEventListener('formChange', (event) => {
        console.log('EVENT FIRED [formChange]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('formSubmit', (event) => {
        console.log('EVENT FIRED [formSubmit]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('formError', (event) => {
        console.log('EVENT FIRED [formError]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('formFocus', (event) => {
        console.log('EVENT FIRED [formFocus]', event);
    });
    document.querySelector('cccc-schema-form').addEventListener('formBlur', (event) => {
        console.log('EVENT FIRED [formBlur]', event);
    });
}, 300)



