import {LitElement, html, css} from 'lit';
import React from "react";
import { createRoot } from 'react-dom/client';
import validator from '@rjsf/validator-ajv8';

const default_schema = {
    title: "Todo",
    type: "object",
    required: ["title"],
    properties: {
        title: {type: "string", title: "Title"},
        done: {type: "boolean", title: "Done?", default: false}
    }
};

export class SchemaFormElement extends LitElement {
    static properties = {
        schema: { type: Object },
        uiSchema: { type: Object },
        theme: {type: String},

        showErrorList: { type: Boolean },
        acceptcharset: { type: String },
        action: { type: String },
        autoComplete: { type: String },
        className: { type: String },
        disabled: { type: Boolean },
        readonly: { type: Boolean },
        enctype: { type: String },
        extraErrors: { type: Object },
        extraErrorsBlockSubmit: { type: Boolean },
        fields: { type: Object },
        focusOnFirstError: { type: Boolean },
        formContext: { type: Object },
        formData: { type: Object },
        id: { type: String },
        idPrefix: { type: String },
        idSeparator: { type: String },
        liveOmit: { type: Boolean },
        liveValidate: { type: Boolean },
        method: { type: String },
        name: { type: String },
        noHtml5Validate: { type: Boolean },
        noValidate: { type: Boolean },
        omitExtraData: { type: Boolean },
        tagName: { type: String },
        target: { type: String },
    };

    constructor() {
        super();

        const innerText = this.innerText;
        try {
            const data = JSON.parse(innerText);
            this.schema = data.schema ? data.schema : data;
            this.uiSchema = data.uiSchema ? data.uiSchema : {};
            this.formData = data.formData ? data.formData : {};
        }catch{
            console.log('no json found inside element... using default schema (and or props)');
            this.schema = default_schema;
            this.uiSchema = {};
            this.formData = {};
        }
        this.theme = 'bootstrap-3'
        this.showErrorList = false;
        this.acceptcharset = 'UTF-8';
        this.action = '';
        this.autoComplete = 'off';
        this.className = '';
        this.disabled = false;
        this.readonly = false;
        this.enctype = 'application/json';
        this.extraErrors = {};
        this.extraErrorsBlockSubmit = false;
        this.fields = {};
        this.focusOnFirstError = true;
        this.formContext = {};
        this.id = '';
        this.idPrefix = 'root';
        this.idSeparator = '_';
        this.liveOmit = false;
        this.liveValidate = false;
        this.method = 'post';
        this.name = '';
        this.noHtml5Validate = false;
        this.noValidate = false;
        this.omitExtraData = false;
        this.showErrorList = false;
        this.tagName = 'form';
        this.target = '';

    }


    async updated() {
        if (!this.root) {
            const el = this.shadowRoot.querySelector('#react-root')
            if (el)
                this.root = createRoot(el);
            else
                return
        }


        const Form = await this.getThemedForm(this.theme)

        this.root.render(<Form
            schema={this.schema}
            uiSchema={this.uiSchema}
            validator={validator}

            showErrorList={this.showErrorList}
            acceptcharset={this.acceptcharset}
            action={this.action}
            autoComplete={this.autoComplete}
            className={this.className}
            disabled={this.disabled}
            readonly={this.readonly}
            enctype={this.enctype}
            extraErrors={this.extraErrors}
            extraErrorsBlockSubmit={this.extraErrorsBlockSubmit}
            fields={this.fields}
            focusOnFirstError={this.focusOnFirstError}
            formContext={this.formContext}
            formData={this.formData}
            id={this.id}
            idPrefix={this.idPrefix}
            idSeparator={this.idSeparator}
            liveOmit={this.liveOmit}
            liveValidate={this.liveValidate}
            method={this.method}
            name={this.name}
            noHtml5Validate={this.noHtml5Validate}
            noValidate={this.noValidate}
            omitExtraData={this.omitExtraData}
            showErrorList={this.showErrorList}
            tagName={this.tagName}
            target={this.target}

            onChange={this.onChange}
            onError={this.onError}
            onSubmit={this.onSubmit}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
        />);
    }

    render() {
        return html`
            <link id="theme-style" rel="stylesheet">
            <div id="react-root"></div>
        `;
    }

    onError = (errors) =>{
        this.dispatchEvent(new CustomEvent('formError', { detail: {
                errors,
            }}));
    }
    onSubmit = ({ formData }, event) => {
        this.dispatchEvent(new CustomEvent('formSubmit', { detail: {
                formData,
                sourceEvent: event
            }}));
    }
    onChange = ({ formData }, id) => {
        this.dispatchEvent(new CustomEvent('formChange', { detail: {
                formData,
                id,
            }}));
    }

    onFocus = (event) => {
        this.dispatchEvent(new CustomEvent('formFocus', { detail: {sourceEvent: event}}));
    }
    onBlur = (event) => {
        this.dispatchEvent(new CustomEvent('formFocus', { detail: {sourceEvent: event}}));
    }


    async getThemedForm(theme) {
        const cssLink = this.shadowRoot.querySelector('#theme-style');
        let module
        switch(this.theme){
            case 'bootstrap-3':
                cssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css';
                module = await import('@rjsf/core');
                return module.default
            case 'bootstrap-4':
                cssLink.href = 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css';
                module = await import('@rjsf/bootstrap-4');
                return module.default
            // case 'material-ui':
            //     cssLink.href = '';
            //     module = await import('@rjsf/material-ui');
            //     return module.default
            // case 'mui':
            //     cssLink.href = '';
            //     module = await import('@rjsf/mui');
            //     return module.default
            // case 'semantic-ui':
            //     cssLink.href = 'https://cdn.jsdelivr.net/npm/semantic-ui@2.5.0/dist/semantic.min.css';
            //     module = await import('@rjsf/semantic-ui');
            //     return module.default
            case 'antd':
                cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/antd/4.6.6/antd.min.css';
                module = await import('@rjsf/antd');
                return module.default
            // case 'fluent-ui':
            //     cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/fluent-ui/0.0.0/fluent-ui.min.css';
            //     module = await import('@rjsf/fluent-ui');
            //     return module.default
            // case 'chakra-ui':
            //     cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/chakra-ui/0.8.0/chakra-ui.min.css';
            //     module = await import('@rjsf/chakra-ui');
            //     return module.default
            default:
                cssLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.7/css/bootstrap.min.css';
                const msg = `theme '${this.theme}' not found... valid themes are: bootstrap-3, bootstrap-4, antd`
                console.warn ? console.warn(msg) : console.log(msg);
                module = await import('@rjsf/core');
                return module.default
        }
    }
}
customElements.define('cccc-schema-form', SchemaFormElement);
